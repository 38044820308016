import React from 'react'
import { Link } from 'gatsby';
import c from 'classnames';
import SVG from 'react-inlinesvg';
import { withState } from 'recompose';
import styled from 'styled-components';

import Layout from 'components/layout';
import videoFrame from 'assets/images/frame.png';
import iphonesSrc from 'assets/images/hero_iphone.png';
import androidSrc from 'assets/images/android.svg';
import iosSrc from 'assets/images/ios.svg';
import shelfSrc from 'assets/images/shelf.png';
import anotacoesSrc from 'assets/images/anotacoes_landing.png';
import helpers from 'styles/_helpers.module.scss';
import wrappers from 'styles/_wrappers.module.scss';
import styles from 'styles/pages/landing.module.scss';
import Funcionalidades from 'components/Funcionalidades';
import VideoModal from 'components/VideoModal';

const Shadow = styled.div`
  box-shadow: 2px 10px 30px rgba(217, 217, 217, 0.5);
  border-radius: 0.8rem;
  overflow: hidden;
`;

const Landing = props => (
  <Layout location={props.location}>
    <div className={c(styles.hero, wrappers.hero_wrapper)}>
      <img src={iphonesSrc} className={styles.iphones} alt="" />
      <div className={styles.text_wrapper}>
        <div className={c(styles.card, helpers.mt90)}>
          <h2>Simples. Prático. Completo.</h2>
          <h1 className={styles.big_title}>direito anotado</h1>
          <h1 className={styles.entry_title}>
           {String.fromCharCode(160)} A maior coleção de anotações digitais {String.fromCharCode(160)} <br /> {String.fromCharCode(160)} relacionadas com diferentes áreas de  {String.fromCharCode(160)} <br /> {String.fromCharCode(160)} Direito e redigidas por processualistas {String.fromCharCode(160)} <br />{String.fromCharCode(160)} renomados. {String.fromCharCode(160)}
          </h1>
          <h2  className={helpers.mt24}>Aplicativos disponíveis para: </h2>
          <div className={c(wrappers.flex_vam, helpers.mt12)}>
            <SVG src={androidSrc}></SVG>
            <h4 className={helpers.ml8}>Android</h4>
            <SVG className={helpers.ml10} src={iosSrc}></SVG>
            <h4 className={helpers.ml8}>ios</h4>
          </div>
        </div>
      </div>
    </div>

    {props.isVideoOpened && <VideoModal onExit={() => props.setVideoOpened(false)} videoId="4uw22r9XeEE" /> }

    <div className={c(wrappers.wrapper, styles.container_grid)}>
      <Shadow>
        <iframe width="100%" height="380" src="https://www.youtube.com/embed/4uw22r9XeEE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <div className={styles.grid_card}>
          <h3>Conheça o Anotado</h3>
          <p className={helpers.mt12} >Fique a conhecer as funcionalidades de todos os Anotados. Baixe grátis os aplicativos e adquira a versão premium, com todas as anotações, a um preço reduzido!</p>
          <button onClick={props.setVideoOpened} className={c(helpers.mt24,styles.btn)}>Assista ao video</button>
        </div>
      </Shadow>
      <Shadow>
        <img src={shelfSrc} className={helpers.fw} alt="" />
        <div className={styles.grid_card}>
          <h3>Explore nossos aplicativos</h3>
          <p className={helpers.mt12} >O Direito Anotado é uma família de leis que está crescendo! Descubra todos os nossos aplicativos disponíveis para Android e iOS e fique atento aos novos lançamentos.</p>
          <Link to="/produtos" className={c(helpers.mt24,styles.btn)}>ver aplicativos</Link>
        </div>
      </Shadow>
    </div>

    <Funcionalidades />

    <div className={styles.grid_anotacoes}>
       <img src={anotacoesSrc} className={c(helpers.fw, styles.anotacoes_img)} alt="" />
       <div className={helpers.fw}>
          <h5 className={helpers.mb24}>Mais de 250 autores</h5>
          <p>
            As versões Premium do Anotado incluem anotações feitas por renomados processualistas.
          </p>
          <Link to="/autores" className={c(helpers.mt24,styles.btn)}>conheça nossos autores</Link>
       </div>
    </div>
  </Layout>
);

const enhance = withState('isVideoOpened', 'setVideoOpened', false);
export default enhance(Landing);
// export default Landing;
