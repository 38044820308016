import React from 'react'
import c from 'classnames';

import helpers from 'styles/_helpers.module.scss';
import wrappers from 'styles/_wrappers.module.scss';
import styles from 'styles/pages/landing.module.scss';

import saveImg from 'assets/images/iphone_one.png';
import searchImg from 'assets/images/iphone_two.png';
import syncImg from 'assets/images/iphone_four.png';
import offlineImg from 'assets/images/iphone_three.png';

const IMAGES = {
  save: saveImg,
  sync: syncImg,
  offline: offlineImg,
  search: searchImg,
}

export default class Funcionalidades extends React.PureComponent {
  state = {
    currentImage: 'save'
  };

  componentDidMount() {
    // Preload the images
    if (typeof window !== 'undefined') {
      Object.values(IMAGES).forEach((src) => {
        const img = document.createElement('img');
        img.src = src;
      });
    }
  }

  switchOption = (type) => () => this.setState({ currentImage: type });

  render() {
    const { currentImage } = this.state;
    return (
      <div className={c(wrappers.wrapper, styles.container_grid_func, helpers.bg_light_yellow)}>
        <div className={wrappers.flex_col}>
          <button
            onClick={this.switchOption('save')}
            className={c(styles.func_card, helpers.mb12, currentImage === 'save' && styles.active )}
          >
            <div className={wrappers.flex_vam}>
              <h5>Salvar Artigos</h5>
            </div>
            <p className={c(styles.small,helpers.mt12)}>Destaca e salva na sua conta os artigos que forem mais importantes para você.</p>
          </button>
          <button
            onClick={this.switchOption('sync')}
            className={c(styles.func_card, helpers.mb12, helpers.mt12, currentImage === 'sync' && styles.active)}
          >
            <div className={wrappers.flex_vam}>
              <h5>Sincronização da conta</h5>
            </div>
            <p className={c(styles.small,helpers.mt12)}>Sincroniza sua conta em todos os dispositivos.</p>
          </button>
        </div>

        <img src={IMAGES[currentImage]} className={c(helpers.fw, styles.iphone_func)} alt="" />

        <div className={wrappers.flex_col}>
          <button
            onClick={this.switchOption('offline')}
            className={c(styles.func_card, helpers.mb12, helpers.mt12, currentImage === 'offline' && styles.active)}
          >
            <div className={wrappers.flex_vam}>
              <h5>Funcionamento Offline</h5>
            </div>
            <p className={c(styles.small,helpers.mt12)}>Funciona em qualquer lugar, mesmo sem acesso à internet..</p>
          </button>
          <button
            onClick={this.switchOption('search')}
            className={c(styles.func_card, helpers.mt12, currentImage === 'search' && styles.active)}
          >
            <div className={wrappers.flex_vam}>
              <h5>Pesquisa por termo</h5>
            </div>
            <p className={c(styles.small,helpers.mt12)}>Pesquisa um termo em diferentes categorias.</p>
          </button>
        </div>
      </div>
    );
  }
}
