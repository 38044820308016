import React from 'react'

import wrappers from 'styles/_wrappers.module.scss';

const VideoModal = ({ videoId, onExit }) => {
  return (
    <div className={wrappers.modal} onClick={onExit}>
      <div className={wrappers.video_container}>
        <iframe
          // className={styles.video_container}
          title={'Anotado - Youtube Video'}
          width="1920"
          height="1080"
          src={`https://www.youtube.com/embed/${videoId}?rel=0&VQ=HD1080&autoplay=1`}
          frameBorder="0"
          allow="encrypted-media"
          allowFullScreen
        />
      </div>
    </div>
  )
}

export default VideoModal;
